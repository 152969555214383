exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-declaracao-de-uso-de-cookies-js": () => import("./../../../src/pages/declaracao-de-uso-de-cookies.js" /* webpackChunkName: "component---src-pages-declaracao-de-uso-de-cookies-js" */),
  "component---src-pages-home-teste-b-js": () => import("./../../../src/pages/home-teste-b.js" /* webpackChunkName: "component---src-pages-home-teste-b-js" */),
  "component---src-pages-hub-js": () => import("./../../../src/pages/hub.js" /* webpackChunkName: "component---src-pages-hub-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politica-de-privacidade-e-seguranca-js": () => import("./../../../src/pages/politica-de-privacidade-e-seguranca.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-e-seguranca-js" */),
  "component---src-pages-precos-js": () => import("./../../../src/pages/precos.js" /* webpackChunkName: "component---src-pages-precos-js" */),
  "component---src-pages-termos-de-uso-js": () => import("./../../../src/pages/termos-de-uso.js" /* webpackChunkName: "component---src-pages-termos-de-uso-js" */),
  "component---src-templates-landing-page-papoca-js": () => import("./../../../src/templates/landingPagePapoca.js" /* webpackChunkName: "component---src-templates-landing-page-papoca-js" */)
}

